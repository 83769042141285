.login__wrapper {
    display: flex;
    align-items: center;
    height: 100vh;
}
.login__title {
    text-align: center;
    margin-bottom: 50px;
}
.login__panel {
    padding: 50px 0;
    background-color: #ffffff;
}
