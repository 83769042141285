.admin-layout .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
  padding: 24px;
  margin-top: 24px;
  min-height: 300px;
}

